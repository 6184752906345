.App {
  text-align: center;
}

.App-header {
  background-color: #282b30;
  /* min-width: 100vh; */
  min-height: 10vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white;
}

.App-body {
  background-color: #282b30;
  min-height: 80vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white;
}

.App-blog {
  background-color: #282b30;
  /* min-height: 80vh; */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white;
}

.App-footer {
  background-color: #282b30;
  min-height: 10vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white;
}

.Handle-name{
  font-size: 5vh;
  margin-top: 2vh;
  margin-bottom: 3vh;
}

.Icon-link{
  height: 5vh;
}